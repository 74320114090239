// DON'T EDIT. THIS FILE IS GENERATED BY ./manage.py frontend_export
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// backend/contribs/management/commands/frontend_export.py


const CHOICES = {
  "AMSJobGranularLevel": {
    "CHOICES": {
      "1": "Weekly",
      "2": "Monthly",
    },
    "CHOICE_LIST": [
      {
        "label": "Weekly",
        "value": 1,
      },
      {
        "label": "Monthly",
        "value": 2,
      },
    ],
    "MONTHLY": 2,
    "WEEKLY": 1,
  },
  "AMSJobRunType": {
    "BACKPULL": 3,
    "CHOICES": {
      "1": "DD Regular",
      "2": "DD Lite",
      "3": "Backpull",
    },
    "CHOICE_LIST": [
      {
        "label": "DD Regular",
        "value": 1,
      },
      {
        "label": "DD Lite",
        "value": 2,
      },
      {
        "label": "Backpull",
        "value": 3,
      },
    ],
    "DD_LITE": 2,
    "DD_REGULAR": 1,
  },
  "AMSReportStatus": {
    "CHOICES": {
      "1": "Not Requested",
      "2": "Requested",
      "3": "Generated",
      "4": "Downloaded",
      "5": "Converted JSON to CSV",
      "6": "Added Portfolio Names/IDs",
      "7": "Merged",
      "8": "Uploaded",
    },
    "CHOICE_LIST": [
      {
        "label": "Not Requested",
        "value": 1,
      },
      {
        "label": "Requested",
        "value": 2,
      },
      {
        "label": "Generated",
        "value": 3,
      },
      {
        "label": "Downloaded",
        "value": 4,
      },
      {
        "label": "Converted JSON to CSV",
        "value": 5,
      },
      {
        "label": "Added Portfolio Names/IDs",
        "value": 6,
      },
      {
        "label": "Merged",
        "value": 7,
      },
      {
        "label": "Uploaded",
        "value": 8,
      },
    ],
    "CONVERTED_JSON_TO_CSV": 5,
    "DOWNLOADED": 4,
    "GENERATED": 3,
    "MERGED": 7,
    "NOT_REQUESTED": 1,
    "PORTFOLIO_DETAILS_ADDED": 6,
    "REQUESTED": 2,
    "UPLOADED": 8,
  },
  "ActivityActionTypes": {
    "CHOICES": {
      "ACT_EXECUTIVE_SUMMARY": "Executive Summary",
      "ACT_PROJECT": "Project Status",
      "ACT_USER_STATUS": "User Status",
    },
    "CHOICE_LIST": [
      {
        "label": "Executive Summary",
        "value": "ACT_EXECUTIVE_SUMMARY",
      },
      {
        "label": "Project Status",
        "value": "ACT_PROJECT",
      },
      {
        "label": "User Status",
        "value": "ACT_USER_STATUS",
      },
    ],
    "EXECUTIVE_SUMMARY": "ACT_EXECUTIVE_SUMMARY",
    "PROJECT": "ACT_PROJECT",
    "USER_STATUS": "ACT_USER_STATUS",
  },
  "CompanyKind": {
    "BOTH": 3,
    "CHOICES": {
      "1": "Seller Central",
      "2": "Vendor Central",
      "3": "VC & SC",
    },
    "CHOICE_LIST": [
      {
        "label": "Seller Central",
        "value": 1,
      },
      {
        "label": "Vendor Central",
        "value": 2,
      },
      {
        "label": "VC & SC",
        "value": 3,
      },
    ],
    "SELLER_CENTRAL": 1,
    "VENDOR_CENTRAL": 2,
  },
  "DDLiteJobKind": {
    "AMS": 1,
    "AMS_BACKPULL": 3,
    "CHOICES": {
      "1": "AMS API Process",
      "2": "MWS API Process",
      "3": "AMS Backpull Process",
      "4": "MWS Backload",
    },
    "CHOICE_LIST": [
      {
        "label": "AMS API Process",
        "value": 1,
      },
      {
        "label": "MWS API Process",
        "value": 2,
      },
      {
        "label": "AMS Backpull Process",
        "value": 3,
      },
      {
        "label": "MWS Backload",
        "value": 4,
      },
    ],
    "MWS": 2,
    "MWS_BACKLOAD": 4,
  },
  "DDLiteJobStatus": {
    "CHOICES": {
      "1": "Not Processed",
      "2": "Currently Running",
      "3": "Job Completed",
    },
    "CHOICE_LIST": [
      {
        "label": "Not Processed",
        "value": 1,
      },
      {
        "label": "Currently Running",
        "value": 2,
      },
      {
        "label": "Job Completed",
        "value": 3,
      },
    ],
    "COMPLETED": 3,
    "NOT_PROCESSED": 1,
    "RUNNING": 2,
  },
  "DatabaseType": {
    "CHOICES": {
      "1": "My SQL",
      "2": "PostgresSQL",
    },
    "CHOICE_LIST": [
      {
        "label": "My SQL",
        "value": 1,
      },
      {
        "label": "PostgresSQL",
        "value": 2,
      },
    ],
    "MSQL": 1,
    "PSQL": 2,
  },
  "ErrorType": {
    "CHOICES": {
      "": "No Error",
      "404": "404 Error",
    },
    "CHOICE_LIST": [
      {
        "label": "No Error",
        "value": "",
      },
      {
        "label": "404 Error",
        "value": "404",
      },
    ],
    "ERROR_404": "404",
    "NO_ERROR": "",
  },
  "FileProcessStatus": {
    "CHOICES": {
      "1": "Not Process",
      "2": "File Picked",
      "3": "Processing",
      "4": "Processed",
      "5": "Failed",
    },
    "CHOICE_LIST": [
      {
        "label": "Not Process",
        "value": 1,
      },
      {
        "label": "File Picked",
        "value": 2,
      },
      {
        "label": "Processing",
        "value": 3,
      },
      {
        "label": "Processed",
        "value": 4,
      },
      {
        "label": "Failed",
        "value": 5,
      },
    ],
    "FAILED": 5,
    "NOT_PROCESS": 1,
    "PICKED": 2,
    "PROCESSED": 4,
    "PROCESSING": 3,
  },
  "GeneralNoteKind": {
    "CHOICES": {
      "1": "Weekly Business Review",
    },
    "CHOICE_LIST": [
      {
        "label": "Weekly Business Review",
        "value": 1,
      },
    ],
    "WBR": 1,
  },
  "GranularLevel": {
    "CHOICES": {
      "1": "Week",
      "2": "Month",
    },
    "CHOICE_LIST": [
      {
        "label": "Week",
        "value": 1,
      },
      {
        "label": "Month",
        "value": 2,
      },
    ],
    "MONTH": 2,
    "WEEK": 1,
  },
  "ItemStatus": {
    "ACCEPTED": 2,
    "CHOICES": {
      "1": "In Progress",
      "2": "Accepted",
      "3": "Rejected",
    },
    "CHOICE_LIST": [
      {
        "label": "In Progress",
        "value": 1,
      },
      {
        "label": "Accepted",
        "value": 2,
      },
      {
        "label": "Rejected",
        "value": 3,
      },
    ],
    "IN_PROGRESS": 1,
    "REJECTED": 3,
  },
  "JobRunType": {
    "CHOICES": {
      "1": "Regular",
      "2": "DD Lite Auto Data Load",
    },
    "CHOICE_LIST": [
      {
        "label": "Regular",
        "value": 1,
      },
      {
        "label": "DD Lite Auto Data Load",
        "value": 2,
      },
    ],
    "DDLITE_AUTO_DATA_LOAD": 2,
    "REGULAR": 1,
  },
  "JobStatus": {
    "CHOICES": {
      "1": "Queued",
      "2": "Started",
      "3": "Complete",
      "4": "No Request",
    },
    "CHOICE_LIST": [
      {
        "label": "Queued",
        "value": 1,
      },
      {
        "label": "Started",
        "value": 2,
      },
      {
        "label": "Complete",
        "value": 3,
      },
      {
        "label": "No Request",
        "value": 4,
      },
    ],
    "COMPLETE": 3,
    "NO_REQUEST": 4,
    "QUEUED": 1,
    "STARTED": 2,
  },
  "NotificationChoices": {
    "ALL_OFF": 0,
    "ALL_ON": 1,
    "CHOICES": {
      "0": "Do not Disturb",
      "1": "Receive all Notifications",
      "2": "Project Notifications",
      "3": "Executive Summary",
      "4": "Payments",
    },
    "CHOICE_LIST": [
      {
        "label": "Do not Disturb",
        "value": 0,
      },
      {
        "label": "Receive all Notifications",
        "value": 1,
      },
      {
        "label": "Project Notifications",
        "value": 2,
      },
      {
        "label": "Executive Summary",
        "value": 3,
      },
      {
        "label": "Payments",
        "value": 4,
      },
    ],
    "EXECUTIVE_SUMMARY": 3,
    "PAYMENTS": 4,
    "PROJECT": 2,
  },
  "NullValue": {
    "CHOICES": {
      "0": "Zero (0)",
      "No Keyword": "No Keyword",
    },
    "CHOICE_LIST": [
      {
        "label": "Zero (0)",
        "value": "0",
      },
      {
        "label": "No Keyword",
        "value": "No Keyword",
      },
    ],
    "NO_KEYWORD": "No Keyword",
    "ZERO": "0",
  },
  "POReportStatus": {
    "APPROVED": 2,
    "CHOICES": {
      "1": "Created",
      "2": "Approved",
      "3": "Published",
      "4": "Client Approved",
    },
    "CHOICE_LIST": [
      {
        "label": "Created",
        "value": 1,
      },
      {
        "label": "Approved",
        "value": 2,
      },
      {
        "label": "Published",
        "value": 3,
      },
      {
        "label": "Client Approved",
        "value": 4,
      },
    ],
    "CLIENT_APPROVED": 4,
    "CREATED": 1,
    "PUBLISHED": 3,
  },
  "PermissionRunStatus": {
    "CHOICES": {
      "_FAILED_": "Failed",
      "_NOT_STARTED_": "Not Started",
      "_RUNNING_": "Running",
      "_SUCCESS_": "Success",
    },
    "CHOICE_LIST": [
      {
        "label": "Failed",
        "value": "_FAILED_",
      },
      {
        "label": "Not Started",
        "value": "_NOT_STARTED_",
      },
      {
        "label": "Running",
        "value": "_RUNNING_",
      },
      {
        "label": "Success",
        "value": "_SUCCESS_",
      },
    ],
    "FAILED": "_FAILED_",
    "NOT_STARTED": "_NOT_STARTED_",
    "RUNNING": "_RUNNING_",
    "SUCCESS": "_SUCCESS_",
  },
  "PlanNameChoice": {
    "CHOICES": {
      "1": "DD Lite",
      "2": "DD Premium",
      "3": "DD Custom",
    },
    "CHOICE_LIST": [
      {
        "label": "DD Lite",
        "value": 1,
      },
      {
        "label": "DD Premium",
        "value": 2,
      },
      {
        "label": "DD Custom",
        "value": 3,
      },
    ],
    "DD_CUSTOM": 3,
    "DD_LITE": 1,
    "DD_PREMIUM": 2,
  },
  "PlanType": {
    "CHOICES": {
      "1": "Trail",
      "2": "Monthly",
      "3": "Yearly",
    },
    "CHOICE_LIST": [
      {
        "label": "Trail",
        "value": 1,
      },
      {
        "label": "Monthly",
        "value": 2,
      },
      {
        "label": "Yearly",
        "value": 3,
      },
    ],
    "MONTHLY": 2,
    "TRAIL": 1,
    "YEARLY": 3,
  },
  "ProcessLogName": {
    "AMS_BACKPULL": 10,
    "AMS_PROCESS": 2,
    "CHOICES": {
      "1": "Seller Central Data Pull",
      "2": "Advertising Data Pull",
      "3": "Amazon Data Integration",
      "4": "Master ASIN List Creation",
      "5": "Sales Forecast Creation",
      "6": "Inventory Recommendation",
      "7": "Customer Engagement Data Collection",
      "8": "Business Reports Creation",
      "9": "Deep Data Pull",
      "10": "Advertising Data - Backpull",
    },
    "CHOICE_LIST": [
      {
        "label": "Seller Central Data Pull",
        "value": 1,
      },
      {
        "label": "Advertising Data Pull",
        "value": 2,
      },
      {
        "label": "Amazon Data Integration",
        "value": 3,
      },
      {
        "label": "Master ASIN List Creation",
        "value": 4,
      },
      {
        "label": "Sales Forecast Creation",
        "value": 5,
      },
      {
        "label": "Inventory Recommendation",
        "value": 6,
      },
      {
        "label": "Customer Engagement Data Collection",
        "value": 7,
      },
      {
        "label": "Business Reports Creation",
        "value": 8,
      },
      {
        "label": "Deep Data Pull",
        "value": 9,
      },
      {
        "label": "Advertising Data - Backpull",
        "value": 10,
      },
    ],
    "DATA_LOAD": 3,
    "DEEP_DATA_PULL": 9,
    "EXECUTIVE_SUMMARY_GENERATE": 8,
    "FORECAST": 5,
    "INVENTORY_RECOMMENDATION": 6,
    "LOAD_ASIN_LIST": 4,
    "MWS_PROCESS": 1,
    "SCRAPPING_PROCESS": 7,
  },
  "ProcessLogStatus": {
    "CHOICES": {
      "1": "Created",
      "2": "Running",
      "3": "Success",
      "4": "Fail",
    },
    "CHOICE_LIST": [
      {
        "label": "Created",
        "value": 1,
      },
      {
        "label": "Running",
        "value": 2,
      },
      {
        "label": "Success",
        "value": 3,
      },
      {
        "label": "Fail",
        "value": 4,
      },
    ],
    "CREATED": 1,
    "FAIL": 4,
    "RUNNING": 2,
    "SUCCESS": 3,
  },
  "ProcessStatus": {
    "CHOICES": {
      "1": "Started",
      "2": "Finished",
    },
    "CHOICE_LIST": [
      {
        "label": "Started",
        "value": 1,
      },
      {
        "label": "Finished",
        "value": 2,
      },
    ],
    "FINISHED": 2,
    "STARTED": 1,
  },
  "ProcessType": {
    "CHOICES": {
      "1": "SC",
      "2": "VC",
    },
    "CHOICE_LIST": [
      {
        "label": "SC",
        "value": 1,
      },
      {
        "label": "VC",
        "value": 2,
      },
    ],
    "SC": 1,
    "VC": 2,
  },
  "ProductKind": {
    "BOTH": 3,
    "CHOICES": {
      "1": "Seller Central",
      "2": "Vendor Central",
      "3": "VC & SC",
    },
    "CHOICE_LIST": [
      {
        "label": "Seller Central",
        "value": 1,
      },
      {
        "label": "Vendor Central",
        "value": 2,
      },
      {
        "label": "VC & SC",
        "value": 3,
      },
    ],
    "SELLER_CENTRAL": 1,
    "VENDOR_CENTRAL": 2,
  },
  "ProjectSizeChoices": {
    "CHOICES": {
      "1": "XS",
      "2": "S",
      "3": "M",
      "4": "L",
      "5": "XL",
    },
    "CHOICE_LIST": [
      {
        "label": "XS",
        "value": 1,
      },
      {
        "label": "S",
        "value": 2,
      },
      {
        "label": "M",
        "value": 3,
      },
      {
        "label": "L",
        "value": 4,
      },
      {
        "label": "XL",
        "value": 5,
      },
    ],
    "L": 4,
    "M": 3,
    "S": 2,
    "XL": 5,
    "XS": 1,
  },
  "RecordKind": {
    "CHOICES": {
      "1": "Seller Central",
      "2": "Vendor Central",
    },
    "CHOICE_LIST": [
      {
        "label": "Seller Central",
        "value": 1,
      },
      {
        "label": "Vendor Central",
        "value": 2,
      },
    ],
    "SELLER_CENTRAL": 1,
    "VENDOR_CENTRAL": 2,
  },
  "ReportTypeChoices": {
    "CHOICES": {
      "1": "Tableau",
      "2": "Power BI",
    },
    "CHOICE_LIST": [
      {
        "label": "Tableau",
        "value": 1,
      },
      {
        "label": "Power BI",
        "value": 2,
      },
    ],
    "POWER_BI": 2,
    "TABLEAU": 1,
  },
  "RunSequence": {
    "CHOICES": {
      "_FIRST_RUN_": "First Run",
      "_SECOND_RUN_": "Second Run",
      "_THIRD_RUN_": "Third Run",
    },
    "CHOICE_LIST": [
      {
        "label": "First Run",
        "value": "_FIRST_RUN_",
      },
      {
        "label": "Second Run",
        "value": "_SECOND_RUN_",
      },
      {
        "label": "Third Run",
        "value": "_THIRD_RUN_",
      },
    ],
    "FIRST_RUN": "_FIRST_RUN_",
    "SECOND_RUN": "_SECOND_RUN_",
    "THIRD_RUN": "_THIRD_RUN_",
  },
  "SalesDetailConfigNameChoice": {
    "AMS": 3,
    "CHOICES": {
      "1": "Coupons",
      "2": "Deals",
      "3": "AMS",
      "4": "Promo Codes",
      "5": "Seasonality",
      "6": "Total Catalog",
    },
    "CHOICE_LIST": [
      {
        "label": "Coupons",
        "value": 1,
      },
      {
        "label": "Deals",
        "value": 2,
      },
      {
        "label": "AMS",
        "value": 3,
      },
      {
        "label": "Promo Codes",
        "value": 4,
      },
      {
        "label": "Seasonality",
        "value": 5,
      },
      {
        "label": "Total Catalog",
        "value": 6,
      },
    ],
    "COUPONS": 1,
    "DEALS": 2,
    "PROMO_CODES": 4,
    "SEASONALITY": 5,
    "TOTAL_CATALOG": 6,
  },
  "ScrapperKind": {
    "CHOICES": {
      "1": "Main Product",
      "2": "Question and Answer",
      "3": "Review",
    },
    "CHOICE_LIST": [
      {
        "label": "Main Product",
        "value": 1,
      },
      {
        "label": "Question and Answer",
        "value": 2,
      },
      {
        "label": "Review",
        "value": 3,
      },
    ],
    "MAIN_PRODUCT": 1,
    "QUESTION_AND_ANSWER": 2,
    "REVIEW": 3,
  },
  "ScrapperRunType": {
    "CHOICES": {
      "_FRE_": "FRE Run",
      "_WEEKLY_": "Weekly Run",
    },
    "CHOICE_LIST": [
      {
        "label": "FRE Run",
        "value": "_FRE_",
      },
      {
        "label": "Weekly Run",
        "value": "_WEEKLY_",
      },
    ],
    "FRE": "_FRE_",
    "WEEKLY": "_WEEKLY_",
  },
  "ScrapperStatus": {
    "CHOICES": {
      "0": "Not Started",
      "1": "Scrapping",
      "2": "Failed",
      "3": "Completed",
    },
    "CHOICE_LIST": [
      {
        "label": "Not Started",
        "value": 0,
      },
      {
        "label": "Scrapping",
        "value": 1,
      },
      {
        "label": "Failed",
        "value": 2,
      },
      {
        "label": "Completed",
        "value": 3,
      },
    ],
    "COMPLETED": 3,
    "FAILED": 2,
    "NOT_STARTED": 0,
    "SCRAPPING": 1,
  },
  "SeleniumJobType": {
    "BRAND_STORE": "_BRAND_STORE_",
    "BRAND_STORE_PAGES": "_BRAND_STORE_PAGES_",
    "CHOICES": {
      "_BRAND_STORE_": "Brand Store",
      "_BRAND_STORE_PAGES_": "Brand Store Pages",
      "_COUPON_": "Search Term - Brands",
      "_DRIVER_": "Selenium Driver",
      "_PAGE_SALES_": "Page Sales",
      "_POST_": "Post",
      "_ST_BRANDS_": "Search Term - Brands",
      "_ST_PRODUCTS_": "Search Term - Products",
    },
    "CHOICE_LIST": [
      {
        "label": "Brand Store",
        "value": "_BRAND_STORE_",
      },
      {
        "label": "Brand Store Pages",
        "value": "_BRAND_STORE_PAGES_",
      },
      {
        "label": "Search Term - Brands",
        "value": "_COUPON_",
      },
      {
        "label": "Selenium Driver",
        "value": "_DRIVER_",
      },
      {
        "label": "Page Sales",
        "value": "_PAGE_SALES_",
      },
      {
        "label": "Post",
        "value": "_POST_",
      },
      {
        "label": "Search Term - Brands",
        "value": "_ST_BRANDS_",
      },
      {
        "label": "Search Term - Products",
        "value": "_ST_PRODUCTS_",
      },
    ],
    "COUPON": "_COUPON_",
    "DRIVER": "_DRIVER_",
    "PAGE_SALES": "_PAGE_SALES_",
    "POST": "_POST_",
    "ST_BRANDS": "_ST_BRANDS_",
    "ST_PRODUCTS": "_ST_PRODUCTS_",
  },
  "SeleniumRunStatus": {
    "CHOICES": {
      "_COMPLETED_": "Completed",
      "_NOT_STARTED_": "Not Started",
      "_RUNNING_": "Running",
    },
    "CHOICE_LIST": [
      {
        "label": "Completed",
        "value": "_COMPLETED_",
      },
      {
        "label": "Not Started",
        "value": "_NOT_STARTED_",
      },
      {
        "label": "Running",
        "value": "_RUNNING_",
      },
    ],
    "COMPLETED": "_COMPLETED_",
    "NOT_STARTED": "_NOT_STARTED_",
    "RUNNING": "_RUNNING_",
  },
  "SeleniumRunType": {
    "CHECK": "_CHECK_",
    "CHOICES": {
      "_CHECK_": "Selenium Code Checks",
      "_FRE_": "FRE Run",
      "_INVITATION_": "Invitation Confirmation",
      "_PERMISSION_": "Permission Checks",
      "_WEEKLY_": "Weekly Run",
    },
    "CHOICE_LIST": [
      {
        "label": "Selenium Code Checks",
        "value": "_CHECK_",
      },
      {
        "label": "FRE Run",
        "value": "_FRE_",
      },
      {
        "label": "Invitation Confirmation",
        "value": "_INVITATION_",
      },
      {
        "label": "Permission Checks",
        "value": "_PERMISSION_",
      },
      {
        "label": "Weekly Run",
        "value": "_WEEKLY_",
      },
    ],
    "FRE": "_FRE_",
    "INVITATION": "_INVITATION_",
    "PERMISSION": "_PERMISSION_",
    "WEEKLY": "_WEEKLY_",
  },
  "SourceType": {
    "API": 1,
    "CHOICES": {
      "1": "API",
      "2": "Files",
    },
    "CHOICE_LIST": [
      {
        "label": "API",
        "value": 1,
      },
      {
        "label": "Files",
        "value": 2,
      },
    ],
    "FILES": 2,
  },
  "StripeCouponDuration": {
    "CHOICES": {
      "forever": "FOREVER",
      "once": "ONCE",
      "repeating": "REPEATING",
    },
    "CHOICE_LIST": [
      {
        "label": "FOREVER",
        "value": "forever",
      },
      {
        "label": "ONCE",
        "value": "once",
      },
      {
        "label": "REPEATING",
        "value": "repeating",
      },
    ],
    "FOREVER": "forever",
    "ONCE": "once",
    "REPEATING": "repeating",
  },
  "StripeCurrency": {
    "CHOICES": {
      "usd": "USD",
    },
    "CHOICE_LIST": [
      {
        "label": "USD",
        "value": "usd",
      },
    ],
    "USD": "usd",
  },
  "StripeRecurringInterval": {
    "CHOICES": {
      "day": "DAY",
      "month": "MONTH",
      "week": "WEEK",
      "year": "YEAR",
    },
    "CHOICE_LIST": [
      {
        "label": "DAY",
        "value": "day",
      },
      {
        "label": "MONTH",
        "value": "month",
      },
      {
        "label": "WEEK",
        "value": "week",
      },
      {
        "label": "YEAR",
        "value": "year",
      },
    ],
    "DAY": "day",
    "MONTH": "month",
    "WEEK": "week",
    "YEAR": "year",
  },
  "StripeSubscriptionType": {
    "CHOICES": {
      "1": "DD Lite",
      "2": "DD Premium",
      "3": "DD Custom",
    },
    "CHOICE_LIST": [
      {
        "label": "DD Lite",
        "value": 1,
      },
      {
        "label": "DD Premium",
        "value": 2,
      },
      {
        "label": "DD Custom",
        "value": 3,
      },
    ],
    "DD_CUSTOM": 3,
    "DD_LITE": 1,
    "DD_PREMIUM": 2,
  },
  "SupportKind": {
    "ADVERTISING": 3,
    "CHOICES": {
      "1": "Seller Central Files",
      "2": "Vendor Central Files",
      "3": "Advertising",
    },
    "CHOICE_LIST": [
      {
        "label": "Seller Central Files",
        "value": 1,
      },
      {
        "label": "Vendor Central Files",
        "value": 2,
      },
      {
        "label": "Advertising",
        "value": 3,
      },
    ],
    "SELLER_CENTRAL_FILES": 1,
    "VENDOR_CENTRAL_FILES": 2,
  },
  // new flows for trackers
  // ACCEPTED Will become Completed
  // IN_PROGRESS Will become New Project
  // IN_REVIEW Will becomes Accepted
  "TrackerStatus": {
    //"ACCEPTED": 2, //Completed
    "COMPLETED": 2, //Completed
    "CHOICES": {
      // "1": "In Progress",
      // "2": "Accepted",
      // "3": "Rejected",
      // "4": "In Review",
      "1": "New",
      "2": "Completed",
      "3": "Rejected",
      "4": "In Progress",
    },
    "CHOICE_LIST": [
      // {
      //   "label": "In Progress",
      //   "value": 1,
      // },
      // {
      //   "label": "Accepted",
      //   "value": 2,
      // },
      // {
      //   "label": "Rejected",
      //   "value": 3,
      // },
      // {
      //   "label": "In Review",
      //   "value": 4,
      // },
      {
        "label": "New",
        "value": 1,
      },
      {
        "label": "Completed",
        "value": 2,
      },
      {
        "label": "Rejected",
        "value": 3,
      },
      {
        "label": "Accepted",
        "value": 4,
      },
    ],
    "IN_PROGRESS": 1,
    "NEW": 1,
    "IN_REVIEW": 4,
    "ACCEPTED": 4,
    "REJECTED": 3,
  },
  "UploadStatus": {
    "CHOICES": {
      "1": "Uploading",
      "2": "Finished",
      "3": "Failed",
    },
    "CHOICE_LIST": [
      {
        "label": "Uploading",
        "value": 1,
      },
      {
        "label": "Finished",
        "value": 2,
      },
      {
        "label": "Failed",
        "value": 3,
      },
    ],
    "FAILED": 3,
    "FINISHED": 2,
    "UPLOADING": 1,
  },
  "UserKind": {
    "ACCOUNT_MANAGER": 4,
    "ADMIN": 1,
    "ADMIN_STAFF": 3,
    "CHOICES": {
      "1": "Admin",
      "2": "Client",
      "3": "Admin_Staff",
      "4": "Account_Manager",
    },
    "CHOICE_LIST": [
      {
        "label": "Admin",
        "value": 1,
      },
      {
        "label": "Client",
        "value": 2,
      },
      {
        "label": "Admin_Staff",
        "value": 3,
      },
      {
        "label": "Account_Manager",
        "value": 4,
      },
    ],
    "CLIENT": 2,
  },
  "WeeklyBusinessReviewStatus": {
    "BOTH_ARE_NEGATIVE": 3,
    "BOTH_ARE_POSITIVE": 2,
    "CHOICES": {
      "1": "One of WOW and YOY is in the Negative",
      "2": "Both WOW and YOY are Positive",
      "3": "Both WOW and YOY are Negative",
    },
    "CHOICE_LIST": [
      {
        "label": "One of WOW and YOY is in the Negative",
        "value": 1,
      },
      {
        "label": "Both WOW and YOY are Positive",
        "value": 2,
      },
      {
        "label": "Both WOW and YOY are Negative",
        "value": 3,
      },
    ],
    "ONE_IS_POSITIVE": 1,
  },
};

export default CHOICES;
