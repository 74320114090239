import {
  format,
  getWeek,
  addWeeks,
  startOfWeek as startingWeek,
  endOfWeek as endingWeek,
} from 'date-fns';
import * as dayjs from 'dayjs';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(objectSupport);
dayjs.extend(weekOfYear);

const emptyProxyObject = new Proxy({}, { get: () => '' });

const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === undefined) return true;
  if (val === emptyProxyObject) return true;

  if (
    typeof val === 'function'
    || typeof val === 'number'
    || typeof val === 'boolean'
    || Object.prototype.toString.call(val) === '[object Date]'
  ) {
    return false;
  }

  if (val == null || val.length === 0) {
    // null or 0 length array
    return true;
  }

  if (typeof val === 'object') if (Object.keys(val).length === 0) return true;

  return false;
};

const createrange = function (start, end, step) {
  const arr = [];
  let len = 0;

  step = step === undefined ? 1 : step;

  if (arguments.length === 1) {
    len = start;
    start = 0;
    end = start;
  } else {
    start = start === undefined ? 1 : start;
    end = end === undefined ? 1 : end;
    len = end - start;
  }

  let i = 0;

  while (i <= len) {
    arr.push(start + i * step);

    i += 1;
  }

  return arr;
};

const getNameInitial = (text) => {
  const name = (text === '' || text === null) ? 'NA' : text.split(' ');
  const [firstName, lastName] = name;

  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }

  return `${firstName[0].toUpperCase()}`;
};

const formatNumber = (inputNumber) => inputNumber.toLocaleString(undefined, { maximumFractionDigits: 2 });

const kFormatter = (num) => (Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(2))}k` : Math.sign(num) * Math.abs(num));

const getSystemWeek = (date) => getWeek(date, {
  weekStartsOn: 0,
  firstWeekContainsDate: 7,
});

const dateAlwaysIncludedInLastWeekOfYear = (year) => new Date(`12-31-${year}`);
const dateAlwaysIncludedInFirstWeekOfYear = (year) => new Date(`01-07-${year}`);

const checkHowManyWeeksInYear = (year) => {
  const dateIncludedLastWeek = dateAlwaysIncludedInLastWeekOfYear(year);
  return getSystemWeek(dateIncludedLastWeek);
};

const getCurrentWeekLegacy = () => {
  const currentDate = new Date();
  return getSystemWeek(currentDate);
};

const getCurrentWeek = () => {
  const currentDate = new Date();
  return dayjs(currentDate).week();
  // return getSystemWeek(currentDate);
};

function startOfWeekLegacy(year, week) {
  const dateIncludedFirstWeek = dateAlwaysIncludedInFirstWeekOfYear(year);
  return format(startingWeek(addWeeks(dateIncludedFirstWeek, week - 1)), 'MMM-dd');
}

function startOfWeek(year, week) {
  return dayjs({
    year,
    month: 1,
    day: 1,
  }).week(week).startOf('week').format('MMM-DD');
}

function endOfWeekLegacy(year, week) {
  const dateIncludedFirstWeek = dateAlwaysIncludedInFirstWeekOfYear(year);
  return format(endingWeek(addWeeks(dateIncludedFirstWeek, week - 1)), 'MMM-dd');
}

function endOfWeek(year, week) {
  return dayjs({
    year,
    month: 1,
    day: 1,
  }).week(week).endOf('week').format('MMM-DD');
}

const generateWeeksLegacy = (year, week) => `${startOfWeekLegacy(year, week)} to ${endOfWeekLegacy(year, week)}`;

const generateWeeks = (year, week) => {
  if (year < 2023) {
    return `${startOfWeekLegacy(year, week)} to ${endOfWeekLegacy(year, week)}`;
  }

  return `${startOfWeek(year, week)} to ${endOfWeek(year, week)}`;
};

const generateWeeksForPreviousYears = (year) => {
  const arr = [];

  if (year > 2023) {
    for (let i = 1; i <= dayjs(`${year}-01-01`).isoWeeksInYear(); i += 1) {
      arr.push(
        `${i} (${generateWeeks(year, i)})`,
      );
    }
  } else {
    for (let i = 1; i <= checkHowManyWeeksInYear(year); i += 1) {
      arr.push(
        `${i} (${generateWeeks(year, i)})`,
      );
    }
  }

  return arr;
};

const camelToSnake = (str) => str.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);

const removePrefix = (prefixWithId) => prefixWithId.split('-').slice(1).join('-');

const decodeBase64 = (base64) => {
  const binString = atob(base64);
  const unicodeString = Uint8Array.from(binString, (m) => m.codePointAt(0));
  return new TextDecoder().decode(unicodeString);
};

export {
  isEmpty,
  emptyProxyObject,
  createrange,
  getNameInitial,
  formatNumber,
  kFormatter,
  startOfWeek,
  startOfWeekLegacy,
  endOfWeek,
  endOfWeekLegacy,
  camelToSnake,
  removePrefix,
  checkHowManyWeeksInYear,
  generateWeeks,
  generateWeeksLegacy,
  generateWeeksForPreviousYears,
  getCurrentWeek,
  getCurrentWeekLegacy,
  decodeBase64,
};
