import React from "react";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-text">
        <img className="d8LoaderLogo"  height={50} alt="" src={"/img/brand-logo.png"} />
      </div>
    </div>
  );
};

export default Loader;
