import { thunk, action } from "easy-peasy";
import graphql from "babel-plugin-relay/macro";

import { AUTH_TOKEN, TOKEN_TTL } from "utils/constants";
import { mutate } from "utils/relay";
import CHOICES from "CHOICES";

const mutation = graphql`
  mutation AuthRefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      token
      payload
    }
  }
`;

const AuthStore = {
  currentUser: null,
  authToken: window.localStorage.getItem(AUTH_TOKEN),
  isShowSessionLogoutPopup: false,
  isClient: false,
  isClientAdministrator: false,

  toggleShowSessionLogoutPopup: action(state => {
    state.isShowSessionLogoutPopup = !state.isShowSessionLogoutPopup;
  }),

  setShowSessionLogoutPopup: action((state, input) => {
    state.isShowSessionLogoutPopup = input;
  }),

  setAuthToken: action((state, authToken) => {
    state.authToken = authToken;
  }),

  setOpsFlag: action((state, isClient) => {
    state.isClient = isClient;
  }),

  setClientAdminFlag: action((state, isClientAdministrator) => {
    state.isClientAdministrator = isClientAdministrator;
  }),

  setCurrentUser: action((state, currentUser) => {
    state.currentUser = currentUser;
    state.isClient =
      currentUser && currentUser.kind === CHOICES.UserKind.CLIENT
        ? true
        : false;

    state.isClientAdministrator = currentUser && currentUser.is_client_administrator;
  }),

  updateAuthToken: thunk((actions, authToken) => {
    window.localStorage.setItem(AUTH_TOKEN, authToken);
    actions.setAuthToken(authToken);
  }),

  clearAuthToken: thunk(actions => {
    window.localStorage.clear();
    actions.setAuthToken(null);
    actions.setCurrentUser(null);
    actions.setShowSessionLogoutPopup(false);
  }),

  setCurrentUserData: action((state, currentUser) => {
    state.currentUser = currentUser;
  }),

  fetchAuthToken: thunk(
    (actions, { mutation, input, onSuccess, onFailure }) => {
      mutate({
        mutation,
        input,
        onSuccess(data) {
          actions.updateAuthToken(data.login.token);
          onSuccess(data);
        },
        onFailure(messages) {
          actions.clearAuthToken();
          onFailure(messages);
        },
      });
    },
  ),

  initRefreshToken: thunk(actions => {
    setInterval(() => {
      if (window.localStorage.getItem(AUTH_TOKEN)) {
        mutate({
          mutation,
          input: { token: window.localStorage.getItem(AUTH_TOKEN) },
          onSuccess(data) {
            actions.updateAuthToken(data.refreshToken.token);
          },
          onFailure() {
            actions.clearAuthToken();
          },
        });
      }
    }, TOKEN_TTL);
  }),
};

export default AuthStore;
