import AuthStore from "store/Auth";
import ItemStore from "store/Item";
import MiscStore from "store/Misc";

const Store = {
  auth: AuthStore,
  item: ItemStore,
  misc: MiscStore,
};

export default Store;
